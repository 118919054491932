//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import './core/base/functions';
@import './core/base/mixins';
@import './core/components/mixins';
@import './core/vendors/plugins/mixins';

// Custom variables
@import 'components/variables.custom';
@import 'components/custom';
@import './core/components/variables';
@import './core/components/variables-dark';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import './core/vendors/plugins/variables';
@import './core/vendors/plugins/variables-dark';

// Custom layout variables
@import './core/layout/base/variables';
@import 'layout/variables.custom';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/dist/css/bootstrap.min.css'