.mb-not {
  margin-bottom: 0px !important;
}
.gap_vertical {
  display: flex;
  flex-direction: column;
}
.themcap {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.themcaptrong {
  display: flex;
  height: 62px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #f9fafb;
}
.noidung_themcap {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}
.cha_noidungcuacacnhom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 53, 128, 0.08);
  border-radius: 8px;
}
.cha_cap1_noidungcacnhom {
  display: flex;
  gap: 12px;
  padding: 10px 24px;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(249, 250, 251);
  border-top: 1px solid rgba(0, 53, 128, 0.08);
  border-left: 1px solid rgba(0, 53, 128, 0.08);
  border-right: 1px solid rgba(0, 53, 128, 0.08);
}
.cha_noidung_nhomkhac {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 90%;
}
.cha_cap1_noidung_nhomkhac {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.tieude_noidung_nhomkhac {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.ndung_tieude_nhomkhac {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  color: #00204d;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.button_nhomkhac {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #366ae2;
}
.themcap_nhomkhac {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.themcaptrong_nhomkhac {
  display: flex;
  height: 62px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background-color: #f9fafb;
}
.box1 {
  display: flex;
  width: 372px;
  padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.cha_box {
  display: flex;
  padding-bottom: 0px;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}
.box2 {
  display: flex;
  align-items: flex-start;
  gap: 64px;
  flex: 1 0 0;
  align-self: stretch;
}
#danhsach_hopdong {
  .table_action {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background: #fff;
    width: 95%;
  }
  .detailed_action {
    display: flex;
    align-items: center;
    gap: 24px;
    /* flex: 1 0 0; */
  }
  .content_action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    color: #00204d99;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
  }
  .btn-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .detailed-btn-action {
    display: flex;
    height: 32px;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border-radius: 4px;
  }
}
#popupbaogiam {
  .container_baogiam {
    display: flex;
    /* padding: 16px 32px 24px 24px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
  }
  .content_firstline_baogiam {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    .textfield {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
      }
      .input_firstline {
        display: flex;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid rgba(0, 53, 128, 0.2);
        background: #fff;
        padding: 10px 12px;
        gap: 12px;
        flex: 1 0 0;
        &:focus {
          outline: 0;
        }
      }
    }
    .filtereditem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 29px;
      align-self: stretch;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }
  .content_secondline_baogiam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    flex: 1 0 0;
    .label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }
    .input {
      display: flex;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid rgba(0, 53, 128, 0.2);
      background: #fff;
      padding: 10px 12px;
      gap: 12px;
      flex: 1 0 0;
      &:focus {
        outline: 0;
      }
    }
  }
  .content_thirdline_baogiam {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .label {
      display: flex;
      align-items: flex-start;
      gap: 4px;
      color: #00204de5;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      align-self: stretch;
    }
  }
  .textfield {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    .input1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      height: 32px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid rgba(0, 53, 128, 0.2);
      background: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      padding: 10px 12px;
      &:focus {
        outline: none;
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 4px;
    }
  }
}
.footer {
  display: flex;
  padding: 12px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  /* Shadow/Shadow top */
  .combo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    .btn_footer {
      display: flex;
      height: 32px;
      padding: 0px 12px;
      align-items: center;
      gap: 8px;
      color: #00204d99;
      outline: none;
      border: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      border-radius: 4px;
      background: #f2f5f8;
    }
  }
}
#step1 {
  /* display: flex; */
  width: 71%;
  padding: 24px 32px 0px 32px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  .container_step1 {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 44px;
    align-self: stretch;
    .row_item {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      .container_label {
        display: flex;
        width: 240px;
        padding: 4px 0px;
        align-items: center;
        gap: 12px;
        .style_size_label {
          font-size: 16px !important;
          font-style: normal;
          font-weight: 600 !important;
          line-height: 24px;
        }
      }
      .container_select {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        gap: 8px;
        flex: 1 0 0;
        .style_size_select {
          font-size: 14px !important;
          font-style: normal;
          font-weight: 400 !important;
          line-height: 22px;
        }
      }
      .container_radio {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        .inner_radio {
          display: flex;
          height: 62px;
          padding: 20px 16px;
          justify-content: center;
          align-items: center;
          gap: 24px;
          align-self: stretch;
          border-radius: 8px;
          background: #f9fafb;
          .detailed_radio_label {
            display: flex;
            height: 20px;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
          }
          .textfield {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            width: 300px;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid rgba(0, 53, 128, 0.2);
            background-blend-mode: multiply;
          }
        }
      }
      .container_input {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        .col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
          flex: 1 0 0;
          .textfield {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2px;
            align-self: stretch;
            .label {
              display: flex;
              align-items: flex-start;
              gap: 4px;
              align-self: stretch;
            }
            .select {
              align-items: center;
              align-self: stretch;
              border-radius: 4px;
              background: #fff;
              gap: 1px;
              flex: 1 0 0;
              display: grid;
              max-height: 32px;
              .style {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
          .textfield_address {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            .label {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
            .container_address {
              display: flex;
              align-items: flex-start;
              gap: 8px;
              flex-direction: column;
              justify-content: center;
              flex: 1 0 0;
              .select {
                align-items: center;
                align-self: stretch;
                border-radius: 4px;
                background: #fff;
                gap: 12px;
                flex: 1 0;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
.btn_footer_step {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  margin-top: 10px;
  margin-right: 22px;
  margin-bottom: 10px;
  .btn_custom_continue {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #366ae2;
    height: 32px;
    padding: 0px 12px;
    outline: none;
    border: none;
    cursor: pointer;
    /* .label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: #ffffff;
    } */
  }
  .label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #ffffff;
  }
}
#step2 {
  display: flex;
  padding: 24px 32px 0px 32px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  .frame {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 44px;
    flex: 1 0 0;
    .frame_con {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 44px;
      .frame_dau {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        .list_item {
          display: flex;
          /* width: 240px; */
          padding: 4px 0px;
          align-items: center;
          gap: 12px;
          .content {
            display: flex;
            width: 240px;
            padding: 4px 0px;
            align-items: center;
            gap: 12px;
            .frame {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              align-self: stretch;
              .style {
                color: var(#00204d);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
              }
            }
          }
        }
        .frame_noidung {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          flex: 1 0 0;
          .frame_con_1 {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            .textfield {
              display: flex;
              width: 200px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 8px;
              .label {
                display: flex;
                align-items: flex-start;
                gap: 4px;
                align-self: stretch;
                .content {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 8px;
                  .frame {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    .style {
                      color: rgba(0, 32, 77, 0.9);
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 22px;
                    }
                  }
                }
              }
              .placetext_holder {
                display: flex;
                height: 32px;
                align-items: center;
                align-self: stretch;
                border-radius: 4px;
                border: 1px solid rgba(0, 53, 128, 0.2);
                background: #fff;
                /* .frame {
                  display: flex;
                  padding: 0px 12px;
                  align-items: center;
                  gap: 12px;
                  flex: 1 0 0;
                  align-self: stretch;
                  .placetext {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    flex: 1 0 0;
                    .frame {
                      display: flex;
                      padding: 0px 12px;
                      align-items: center;
                      gap: 12px;
                      flex: 1 0 0;
                      align-self: stretch;
                    }
                  }
                } */
              }
            }
            .filter_item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              .label {
                display: flex;
                width: 160px;
                align-items: center;
                gap: 4px;
                .frame_con {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  .style {
                    color: rgba(0, 32, 77, 0.9);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                  }
                }
              }
              .combo_textfield_cha {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                .combo_textfield_con {
                  display: flex;
                  align-items: flex-start;
                  gap: 6px;
                  align-self: stretch;
                  .textfield {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    /* flex: 1 0 0; */
                    .placetext_holder {
                      display: flex;
                      height: 42px;
                      align-items: center;
                      align-self: stretch;
                      border-radius: 4px;
                      background: #fff;
                      width: 200px;
                      padding: 0px 12px;
                    }
                    .placetext_holder:focus {
                      outline: none;
                    }
                  }
                  .frame_icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    .media {
                      display: flex;
                      align-items: flex-start;
                      border-radius: 4px;
                      .media_style {
                        display: flex;
                        width: 12px;
                        height: 12px;
                        justify-content: center;
                        align-items: center;
                        .style {
                          width: 12px;
                          height: 12px;
                          flex-shrink: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .frame_noidung2 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid rgba(0, 53, 128, 0.08);
            padding-bottom: 24px;
            .list_item {
              display: flex;
              padding: 10px 24px;
              align-items: center;
              gap: 12px;
              align-self: stretch;
              border-radius: 8px 8px 0px 0px;
              border-top: 1px solid rgba(0, 53, 128, 0.08);
              border-right: 1px solid rgba(0, 53, 128, 0.08);
              border-left: 1px solid rgba(0, 53, 128, 0.08);
              background: #f9fafb;
              width: 100%;
              .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                .frame {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  align-self: stretch;
                  .style {
                    color: rgba(0, 32, 77, 0.8);
                    align-self: stretch;
                    /* Label/Label 4 • 14px */
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
              }
              .btn_themcap {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                .btn_themcap_con {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                }
                .style {
                  color: #366ae2;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22px; /* 157.143% */
                }
              }
            }
            .frame_caphanmuc {
              display: inline-table;
              height: 62px;
              padding: 0px 6px;
              justify-content: center;
              align-items: center;
              gap: 16px;
              align-self: stretch;
              border-radius: 8px;
              .frame_caphanmuc_con {
                display: flex;
                height: 62px;
                padding: 5px 16px;
                justify-content: center;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                border-radius: 8px;
                background: #f9fafb;
                width: 100%;
                margin-bottom: 1em;
                .textfield {
                  display: flex;
                  gap: 24px;
                  flex: 1 0 0;
                  align-items: center;
                  .label {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    flex: 1 0 0;
                    .style {
                      color: rgba(0, 32, 77, 0.9);
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                  .textfield {
                    display: flex;
                    width: 324px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    .placetext_holder {
                      display: flex;
                      height: 32px;
                      align-items: center;
                      align-self: stretch;
                      border-radius: 4px;
                      border: 1px solid rgba(0, 53, 128, 0.2);
                      background: #fff;
                    }
                  }
                }
                .btn_xoahm {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  min-width: 20px;
                }
              }
            }
          }
        }
      }
      .frame-conlai {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        .list_item {
          display: flex;
          /* width: 240px; */
          padding: 4px 0px;
          align-items: center;
          gap: 12px;
          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            .frame {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              align-self: stretch;
              .style {
                color: #00204d;
                align-self: stretch;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
              }
            }
          }
        }
        .combo_textfield {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          /* flex: 1 0 0; */
          align-self: stretch;
          .combo_textfield_con {
            display: flex;
            align-items: flex-start;
            gap: 6px;
            align-self: stretch;
            .textfield {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              .react-datepicker-wrapper {
                width: 100%;
              }
              .placetext_holder {
                display: flex;
                height: 32px;
                align-items: center;
                align-self: stretch;
                border-radius: 4px;
                border: 1px solid rgba(0, 53, 128, 0.2);
                background: #fff;
                width: 100%;
                padding: 10px;
              }
              .placetext_holder:focus {
                outline: none;
              }
            }
            .frame_icon {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              .media {
                display: flex;
                align-items: flex-start;
                border-radius: 4px;
                .style {
                  display: flex;
                  width: 12px;
                  height: 12px;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
    .frame_table {
      display: flex;
      flex-direction: column;
      /* align-items: flex-start; */
      /* align-self: stretch; */
      /* position: sticky; */
      .table_header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        .frame_table_con {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          /* border-bottom: 1px solid rgba(0, 53, 128, 0.08); */
          .page_header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            .frame_table_con_child {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-self: stretch;
              .page_header_con {
                display: flex;
                padding: 16px 0px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                .content {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 8px;
                  flex: 1 0 0;
                  .frame_content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    flex: 1 0 0;
                    .style {
                      color: #00204d;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 600;
                      align-self: stretch;
                      line-height: 24px; /* 150% */
                    }
                  }
                }
              }
            }
          }
          .table_action {
            display: flex;
            height: 40px;
            justify-content: flex-end;
            align-items: center;
            gap: 32px;
            align-self: stretch;
            border-top: 1px solid rgba(0, 53, 128, 0.08);
            background: #fff;
            width: 100%;
            .frame_left {
              display: flex;
              align-items: center;
              gap: 24px;
              flex: 1 0 0;
              .btn_action {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                .btn_detailed {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  .style {
                    color: rgba(0, 32, 77, 0.6);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px; /* 157.143% */
                  }
                }
              }
            }
            .frame_right {
              display: flex;
              padding: 0px 12px;
              align-items: flex-start;
              gap: 8px;
              .style {
                color: rgba(0, 32, 77, 0.9);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 157.143% */
              }
              .style_content {
                color: rgba(0, 32, 77, 0.9);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
            }
          }
        }
      }
    }
  }
}
.input_number {
  margin-right: 28px;
}

.p-accordion .p-accordion-content {
  padding: 0 !important;
}
/* .pagniation_buoc3 {
  position: relative !important;
  bottom: 0;
  width: 79%;
}
@media screen and (max-height: 860px)  {
  .pagniation_buoc3 {
    position: relative !important;
    bottom: 0;
    width: 100%;
  }
} */