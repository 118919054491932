.card-box {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e7eaed;
  padding: 1.5rem;
  margin-bottom: 24px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.17) !important;
}


// DROPDOWN
.dropdown-toggle::after {
  content: none;
}

.table-dropdown {
  .dropdown-menu.show {
    // transform: none !important;
    // position: fixed !important;
    // inset: auto !important;
  }
  .dropdown-menu.show1 {
    transform: none !important;
    position: fixed !important;
    inset: auto !important;
  }
}


// PAGINATION
.pagination-select {
  .select-custom__control {
    height: 30px;
    min-width: 52px;
    flex-wrap: nowrap;
    min-height: 0 !important;
    justify-content: center;

  }

  .select-custom__value-container {
    justify-content: center;
  }

  .select-custom__control.select-custom__control--is-focused {
    height: 30px;
    min-width: 52px;
    flex-wrap: nowrap;
    border-color: #fff;
    min-height: 0 !important;

    &:hover {
      height: 30px;
      min-width: 52px;
      flex-wrap: nowrap;
      border-color: #fff;
      min-height: 0 !important;
    }
  }
  .select-custom__menu {
    .select-custom__option {
      display: inline-flex;
      justify-content: center;
    }
  }

  .select-custom__indicators {
    display: none;
  }
}


// REACT CHECKBOX TREE
.react-checkbox-tree {
  font-size: 16px;
}

.rct-node-icon {
  display: none;
}

.rct-collapse *, .rct-checkbox *, .rct-node-icon * {
  width: 18px;
}


.standard-input {
  height: 38px;
  font-size: 14px;
}

.documents-input {
  display: none;
}


.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  height: 38px;
}

// tab menu dich vu ky thuat menu sidebar
.menuSidebar {
  width: auto !important;
}
