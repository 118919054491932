//
// Theme style
//

// Initialize
@import 'init';
@import 'primeicons/primeicons.css';
// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';
@import "../../assets/sass/core/layout/tablePrime.scss";
@import "../../assets/sass/core/layout/datePickerCustom.scss";
@import "../../../app/components/form/style.scss";
@import "../../../app/modules//contract-customer/pages/step5/HopDongStep5.css";
body,
html,
div {
    font-family: 'Inter', sans-serif !important;

  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}

.react-datepicker-wrapper {
  width: 100%;
}

.upload-btn {

  .p-button-label {
    font-weight: 500 !important;
    margin-top: 3px;
  }
  
  .p-button {
    color: #3f4254;
    background-color: #e1e3ea;
    border: 0;
    height: 41px;
    font-size: 12.35px;
    font-family: 'Inter', sans-serif;
  }

  .p-button:hover {
    color: #3f4254 !important;
    background-color: #b5b5c3 !important;
  }
}

.disabled_view {
  pointer-events: none;
  opacity: 0.8;
  position: relative;
}