.react-select-error div {
  border-color: #e74c3c;
}

.react-select-error:hover div {
  border-color: #e74c3c;
}

.text-success {
  color: green;
}

.text-error {
  color: purple;
}

.time-input {
  background-color: red;
}

#datepicker .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.react-datepicker-dm_hd {
  width: 100% !important;
}
::-ms-reveal {
  display: none !important;
}
#inputnumber .p-inputnumber {
  display: inline-flex;
  width: 100%;
}
.input_capbaohimem input {
  width: 100%;
  padding: 10px 7px;
  border-radius: 4px;
}
#abcd{

}
#abcdef{
  
}

.p-datepicker table th {
  text-align: center;
}

.custom-table-span {
  border-bottom: 1px solid black;
}

.text-area-content {
  font-size: 12px;
  line-height: 16px;
  color: #00204D99;
  font-weight: 400;
}
