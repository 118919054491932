.filter-dashdoard {
    background-color: #081434;
}
.label-filter-dashboard {
    color: #fff;
}
.filter-input {
    width: 100%;
    height: 38px; /* chiều cao chung */
}
  
.filter-input .react-datepicker-wrapper, 
.filter-input .react-datepicker__input-container input {
    width: 100%;
    height: 100%;
}
  
.filter-input .react-select__control {
    height: 100%;
    min-height: unset;
}
.cart-chart {
    width: 33%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
}
